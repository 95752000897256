import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { AcademicYearService } from 'src/app/core/services/academic-year.service';
import { CVASelectorBase } from '../../../classes/cva-selector-base';
import { IAcademicYear } from '../../../models/academic-year.model';
import { NgIf, NgFor } from '@angular/common';
import { LuIconComponent } from '../../lu-icon/lu-icon.component';
import { AcademicYearCodePipe } from '../../../pipes/academic-year-code.pipe';

@Component({
    selector: 'app-academic-year-selector',
    templateUrl: './academic-year-selector.component.html',
    styleUrls: ['./academic-year-selector.component.scss', '../../../../styles/shared/form-control.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: AcademicYearSelectorComponent, multi: true }
    ],
    imports: [NgIf, LuIconComponent, FormsModule, NgFor, AcademicYearCodePipe]
})
export class AcademicYearSelectorComponent extends CVASelectorBase<IAcademicYear | null> implements OnInit{

    @HostBinding('class.hasError') @Input() public hasError: boolean;

    @Input()
    public applicationType?: string;

    @Input()
    public activeOnly = true;

    @Input()
    public allAcademicYears = false;

    @Input()
    public label = 'What academic year are you applying for?';

    @Input()
    public placeholder = 'Select';

    public academicYears: Array<IAcademicYear>;

    constructor(private academicYearService: AcademicYearService){
        super();
    }

    ngOnInit(){
        this.value = null;
        this.academicYears = [];
        if(this.allAcademicYears){
            this.academicYearService.getAllAcademicYears().subscribe(y => this.academicYears = y)
        }else if(this.activeOnly) {
            this.academicYearService.getActiveAcademicYears(this.applicationType).subscribe((years: Array<IAcademicYear>) => {
                this.addAcademicYears(years);
            });
        }else {
            this.academicYearService.getAcademicYears().subscribe((years: Array<IAcademicYear>) => {
                this.addAcademicYears(years);
            });
        }
    }

    public addAcademicYears(years: Array<IAcademicYear>): void {
        years.forEach(y => {
            if(this.academicYears.length < 1 || !this.academicYears.some(ay => ay.acyrCode === y.acyrCode)){
                this.academicYears.push(y);
            }
        });
    }

    public equals(a: IAcademicYear, b: IAcademicYear): boolean {
        if(a && b) {
            return a.acyrCode == b.acyrCode;
        }

        return a === b;
    }

}
